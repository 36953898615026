import { soundReloadFlagState } from '@jotai/sound-reload-flag-state';
import { selectedNotificationAlarmAtom } from '@jotai/token';
import { useUserInfo } from '@shared/hooks/use-user-info';
import { useAtomValue } from 'jotai';
import { useCallback, useEffect, useMemo, useRef } from 'react';

export const useNotificationSound = () => {
  const { userId } = useUserInfo();

  const soundReloadFlag = useAtomValue(soundReloadFlagState);

  const audioRef = useRef<HTMLAudioElement | null>(null);

  const selectedNotificationAlarmAtomMemo = useMemo(
    () => selectedNotificationAlarmAtom(userId),
    [userId, soundReloadFlag],
  );

  const selectedNotificationAlarm = useAtomValue(selectedNotificationAlarmAtomMemo);

  useEffect(() => {
    if (selectedNotificationAlarm) {
      const { path, volume, usingSound } = selectedNotificationAlarm;

      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current = null;
      }

      if (usingSound) {
        const audio = new Audio(path);
        audio.volume = volume;
        audioRef.current = audio;
      }
    }

    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current = null;
      }
    };
  }, [selectedNotificationAlarm]);

  const playAudio = useCallback(() => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
      audioRef.current.play();
    }
  }, []);

  return {
    playAudio,
  };
};
