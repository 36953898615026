import type { ServiceNotice } from '@apis/swaggers/swagger-docs';
import type { TableTabValue } from '@templates/CustomerManagement/containers/BoardPanel/containers/shared/containers/SearchFilter/states/tabs';
import type { CustomerManagementPanelTitle } from '@templates/CustomerManagement/containers/ControlPanel/containers/CustomerManagementPanelList/constants/panel-items';
import ChatRoomJoinApprovedCard from '@templates/ServiceNoticeDialog/compontents/ServiceNoticeList/components/ChatRoomJoinApprovedCard';
import ChatRoomJoinRequestCard from '@templates/ServiceNoticeDialog/compontents/ServiceNoticeList/components/ChatRoomJoinRequestCard';
import PatientInputInfoCard from '@templates/ServiceNoticeDialog/compontents/ServiceNoticeList/components/PatientInputInfoCard';
import ReservationCanceledCard from '@templates/ServiceNoticeDialog/compontents/ServiceNoticeList/components/ReservationCanceledCard';
import ReservationCreatedCard from '@templates/ServiceNoticeDialog/compontents/ServiceNoticeList/components/ReservationCreatedCard';
import ReservationUpdatedCard from '@templates/ServiceNoticeDialog/compontents/ServiceNoticeList/components/ReservationUpdatedCard';
import { useHasAccessibleChatRoom } from '@templates/ServiceNoticeDialog/hooks/use-has-accessible-chat-room';
import { useServiceListReadStatus } from '@templates/ServiceNoticeDialog/hooks/use-service-list-read-status';
import { useServiceNoticeList } from '@templates/ServiceNoticeDialog/hooks/use-service-notice-list';
import type { ServiceNoticeTabs } from '@templates/ServiceNoticeDialog/types/service-notice-tabs';
import Divider from 'afterdoc-design-system/components/Atoms/Divider/Divider';
import BaseLoading from 'afterdoc-design-system/components/Atoms/Loading/BaseLoading';
import Scrollbar from 'afterdoc-design-system/components/Atoms/Scrollbar/Scrollbar';
import NoData from 'afterdoc-design-system/components/Molecules/NoData/NoData';
import { useCallback, useEffect, useRef } from 'react';

interface ServiceNoticeListProps {
  activeTab: ServiceNoticeTabs['key'];
  handleNavigate: (href: string) => void;
}

export default function ServiceNoticeList({ activeTab, handleNavigate }: ServiceNoticeListProps) {
  const scrollbarRef = useRef<{
    scrollTo: (index: number, behavior?: ScrollBehavior) => void;
  }>(null);

  const { isUpdating, updateMutationAction } = useServiceListReadStatus();
  const { checkChatRoom } = useHasAccessibleChatRoom();
  const { serviceNoticeList, setServiceNoticeList, isLoading, isError, handleScrollToEnd } =
    useServiceNoticeList(activeTab);

  const handleMarkAsRead = useCallback(
    (data: ServiceNotice) => {
      const markAsReadCallback = () => {
        setServiceNoticeList((prev) =>
          prev.map((item) => (item._id === data._id ? { ...item, isRead: true } : item)),
        );
      };

      if (data.isRead) {
        handleSubCategoryAction(data);
      } else {
        updateMutationAction(data._id, {
          onSettledCallback: (isSuccess) => {
            if (isSuccess) {
              markAsReadCallback();
            }
            handleSubCategoryAction(data);
          },
        });
      }
    },
    [updateMutationAction],
  );

  const handleSubCategoryAction = useCallback(async (data: ServiceNotice) => {
    switch (data.subCategory) {
      // 채팀방 참여 요청 승인/미승인 완료
      case 'PM_JoinChatRoom_Responder':
      case 'PM_JoinChatRoom_Requestor': {
        const { chatRoomID, status } = data.data ?? {};

        if (data.subCategory === 'PM_JoinChatRoom_Requestor' && status !== 'approved') {
          return;
        }
        if (!(await checkChatRoom(chatRoomID))) return;

        handleNavigate(`/customer-chat?chatRoomId=${chatRoomID}&reload=${Date.now()}`);
        break;
      }
      // 치료태그 입력 필요
      case 'TP_NeedPatient_EmptyTag1000': {
        const tabTitle: CustomerManagementPanelTitle = '치료태그 미입력';
        handleNavigate(`/customer-management?tabTitle=${tabTitle}`);
        break;
      }
      // 고객정보 입력 완료
      case 'TP_NeedPatient_ExistTag1000': {
        const tabTitle: CustomerManagementPanelTitle = '고객정보 입력';
        const tableTabValue: TableTabValue = 'allCustomer';
        handleNavigate(`/customer-management?tabTitle=${tabTitle}&tableTab=${tableTabValue}`);
        break;
      }
      // 고객정보 입력 필요
      case 'TP_NeedPatient_EmptyData1000':
      case 'TP_NeedPatient_EmptyData1800':
      case 'TP_NeedPatient_EmptyData2000': {
        const tabTitle: CustomerManagementPanelTitle = '고객정보 입력';
        const tableTabValue: TableTabValue = 'customerInfo';
        handleNavigate(`/customer-management?tabTitle=${tabTitle}&tableTab=${tableTabValue}`);
        break;
      }
      // 예약관련
      case 'RM_CreateV2Reservation':
      case 'RM_UpdateV2Reservation':
      case 'RM_RemoveV2Reservation': {
        const reservationData = data.data;
        const payload =
          reservationData && 'reservationID' in reservationData && reservationData.reservationID
            ? { value: { _id: reservationData.reservationID } }
            : undefined;
        window?.electron?.ipcRenderer.send('Application.showReservationWindow', payload);
        break;
      }
    }
  }, []);

  useEffect(() => {
    scrollbarRef.current?.scrollTo(0, 'auto');
  }, [activeTab]);

  return (
    <div className='relative flex-grow'>
      <Scrollbar disabledX onScrollToEnd={handleScrollToEnd} ref={scrollbarRef}>
        <div className='flex flex-col'>
          {(serviceNoticeList || []).map((item) => {
            let Component: React.ReactNode;
            switch (item.subCategory) {
              case 'PM_JoinChatRoom_Responder':
                Component = <ChatRoomJoinRequestCard data={item} />;
                break;
              case 'PM_JoinChatRoom_Requestor':
                Component = <ChatRoomJoinApprovedCard data={item} />;
                break;
              case 'TP_NeedPatient_EmptyTag1000':
              case 'TP_NeedPatient_ExistTag1000':
              case 'TP_NeedPatient_EmptyData1000':
              case 'TP_NeedPatient_EmptyData1800':
              case 'TP_NeedPatient_EmptyData2000':
                Component = <PatientInputInfoCard data={item} />;
                break;
              case 'RM_CreateV2Reservation':
                Component = <ReservationCreatedCard data={item} />;
                break;
              case 'RM_UpdateV2Reservation':
                Component = <ReservationUpdatedCard data={item} />;
                break;
              case 'RM_RemoveV2Reservation':
                Component = <ReservationCanceledCard data={item} />;
                break;

              default:
                return null;
            }

            return (
              <div
                key={item._id}
                onClick={() => {
                  handleMarkAsRead(item);
                }}>
                {Component}
                <Divider type={'line'} className={'bg-white400'} />
              </div>
            );
          })}
        </div>
      </Scrollbar>
      {!isLoading && (isError || serviceNoticeList.length === 0) && (
        <div className='absolute inset-10 flex-center bg-white50'>
          <NoData
            title={isError ? '문제가 발생했습니다.\n다시 시도해 주세요' : '표시할 내용이 없습니다.'}
            iconProps={{
              name: 'warning',
              size: 48,
              color: 'white600',
            }}
            className={'flex-center'}
          />
        </div>
      )}
      {(isLoading || isUpdating) && (
        <div className='absolute inset-10 flex-center bg-white50 opacity-40'>
          <BaseLoading />
        </div>
      )}
    </div>
  );
}
