import { useAtom, useAtomValue } from 'jotai';
import { hospitalInfosAtom, selectedHospitalIDAtom } from 'web/jotai/token';

export default function ServiceManagerNavigationBar() {
  const hospitalInfos = useAtomValue(hospitalInfosAtom);
  const [selectedHospitalID, setSelectedHospitalID] = useAtom(selectedHospitalIDAtom);

  const onChangeHospital = (hospitalID: string) => {
    setSelectedHospitalID(hospitalID);
    window.electron?.ipcRenderer.send('Application.Set.hospitalID', hospitalID);
  };

  return (
    <div className='scrollbar-hide fixed top-0 z-50 flex h-screen w-70 select-none flex-col items-center overflow-y-auto border-r border-r-white400 bg-white50 py-30'>
      <div className='flex flex-col gap-36 px-10'>
        {hospitalInfos.map((hospitalInfo) => (
          <div
            key={hospitalInfo._id}
            className='flex cursor-pointer flex-col gap-4'
            onClick={() => {
              if (!hospitalInfo._id || hospitalInfo._id === selectedHospitalID) return;
              onChangeHospital(hospitalInfo._id);
            }}>
            {hospitalInfo.representImage?.paths?.[0] && (
              <img
                src={hospitalInfo.representImage.paths[0]}
                alt={hospitalInfo.shortName}
                className='mx-auto h-30 w-30 rounded-r6'
              />
            )}
            <div className='text-center text-Header12'>{hospitalInfo.shortName}</div>
          </div>
        ))}
      </div>
    </div>
  );
}
