import { QUERY_KEY } from '@apis/swaggers/query-key';
import { useUserInfo } from '@shared/hooks/use-user-info';
import { useQueryClient } from '@tanstack/react-query';
import { useServiceListReadStatus } from '@templates/ServiceNoticeDialog/hooks/use-service-list-read-status';
import { useServiceNoticeList } from '@templates/ServiceNoticeDialog/hooks/use-service-notice-list';
import { useServiceNoticeUnreadCount } from '@templates/ServiceNoticeDialog/hooks/use-service-notice-unread-count';
import type { ServiceNoticeTabs } from '@templates/ServiceNoticeDialog/types/service-notice-tabs';
import Icon from 'afterdoc-design-system/components/Foundations/Icon/Icon';
import { useEffect, useState } from 'react';

interface UnreadNotificationSectionProps {
  activeTab: ServiceNoticeTabs['key'];
}

export default function UnreadNotificationSection({ activeTab }: UnreadNotificationSectionProps) {
  const queryClient = useQueryClient();

  const { userId: userID } = useUserInfo();

  const { unreadCount } = useServiceNoticeUnreadCount(activeTab);
  const { setServiceNoticeList } = useServiceNoticeList();
  const { updateAllMutationAction } = useServiceListReadStatus();

  const [formattedUnreadCount, setFormattedUnreadCount] = useState('');

  const handleClick = () => {
    updateAllMutationAction(userID, activeTab, {
      onSuccessCallback: handleSuccessListener,
    });
  };

  const handleSuccessListener = () => {
    queryClient.invalidateQueries({ queryKey: [QUERY_KEY.apiNoticeServicenoticeElCount] });
    setServiceNoticeList((prev) =>
      prev.map((item) => ({
        ...item,
        isRead: true,
      })),
    );
  };

  useEffect(() => {
    if (unreadCount !== undefined) {
      const formatNumberWithComma = (value: number) => {
        if (value > 9999) {
          return '9,999+';
        }
        return value.toLocaleString();
      };
      setFormattedUnreadCount(formatNumberWithComma(unreadCount));
    }
  }, [unreadCount]);

  return (
    <div className='flex items-center border-white400 border-b bg-blueLight px-20 py-10'>
      <div className='flex flex-grow gap-4'>
        <span className='text-Body10 text-black200'>읽지 않은 알림</span>
        {formattedUnreadCount && (
          <span className='text-Body10Bold'>총 {formattedUnreadCount}개</span>
        )}
      </div>
      <div
        className='flex-center cursor-pointer gap-2 py-2 pr-4 pl-8'
        onClick={handleClick}
        style={{ visibility: unreadCount ? 'visible' : 'hidden' }}>
        <span className='text-Body11 text-blue500'>모두 읽음</span>
        <Icon name={'done'} color={'blue500'} size={16} />
      </div>
    </div>
  );
}
