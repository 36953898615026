import { apiClient } from '@apis/instances/api-client';
import { useDataEventBusHandler } from '@apis/push/data-push/hooks/use-data-event-bus-handler';
import { QUERY_KEY } from '@apis/swaggers/query-key';
import type { ApiChatroomElUnreadcountParams } from '@apis/swaggers/swagger-docs';
import { useSelectedHospitalInfo } from '@shared/hooks/use-selected-hospital-info';
import { useUserInfo } from '@shared/hooks/use-user-info';
import { hasUnreadMessageBadgeState } from '@shared/states/has-unread-message-badge-state';
import type { DataNotificationPayload } from '@shared/utils/event-bus';
import { useQuery } from '@tanstack/react-query';
import { useSetAtom } from 'jotai';
import { useAtomValue } from 'jotai/index';
import { useEffect, useMemo, useRef } from 'react';
import { SHARED_UTILS } from 'utils/utils';
import { chatRoomIDSelector } from 'web/templates/CustomerChat/states/selected-chat-room';

const fetchCustomerUnreadCount = async (parma: ApiChatroomElUnreadcountParams) => {
  const { data } = await apiClient.v3.apiChatroomElUnreadcount(parma);
  return SHARED_UTILS.api.checkApiResponse(data);
};

export const useCustomerUnreadCount = () => {
  const { userId: userID } = useUserInfo();
  const { hospitalID } = useSelectedHospitalInfo();
  const currentChatRoomId = useAtomValue(chatRoomIDSelector);

  const handleNotificationRef = useRef<((payload: DataNotificationPayload) => void) | null>(null);

  const setHasUnreadMessageBadge = useSetAtom(hasUnreadMessageBadgeState);

  const { data, refetch } = useQuery({
    queryKey: [
      QUERY_KEY.apiChatroomElUnreadcount,
      { hospitalID, userID, isDoctor: false, isActive: true, isCounselInProgress: true },
    ] as const,
    queryFn: ({ queryKey }) => fetchCustomerUnreadCount(queryKey[1]),
  });

  const notificationConfigs = useMemo(
    () => [
      {
        codes: ['d_ChatReceive_h'],
        handler: (payload: DataNotificationPayload) => handleNotificationRef.current?.(payload),
      },
    ],
    [],
  );

  useDataEventBusHandler(notificationConfigs);

  useEffect(() => {
    handleNotificationRef.current = (payload: DataNotificationPayload) => {
      const {
        hospitalID: senderHospitalID,
        senderID,
        chatRoomID: senderChatRoomID,
      } = payload.data || {};

      if (
        hospitalID === senderHospitalID &&
        userID !== senderID &&
        currentChatRoomId !== senderChatRoomID
      ) {
        refetch();
      }
    };
  }, [hospitalID, userID, refetch, currentChatRoomId]);

  useEffect(() => {
    if (Array.isArray(data) && data.length > 0) {
      const index = data.findIndex((item) => item._id === userID);
      if (index >= 0) {
        setHasUnreadMessageBadge(data[index].total > 0);
        return;
      }
    }
    setHasUnreadMessageBadge(false);
  }, [data]);
};
