import IconButton from 'afterdoc-design-system/components/Atoms/Button/IconButton';

interface ServiceNoticeDialogHeaderProps {
  onClose: () => void;
}

export default function ServiceNoticeDialogHeader({ onClose }: ServiceNoticeDialogHeaderProps) {
  return (
    <div className='flex justify-center px-20 pt-20 pb-8'>
      <span className='flex-grow text-Header16 text-black700'>알림</span>
      <IconButton icon={'close'} size={24} onClick={onClose} />
    </div>
  );
}
