import type { ServiceNotice } from '@apis/swaggers/swagger-docs';
import { formatCardCreatedDate } from '@templates/ServiceNoticeDialog/functions/format-card-created-date';
import Badge from 'afterdoc-design-system/components/Atoms/Badge/Badge';

interface ChatRoomJoinApprovedCardProps {
  data: ServiceNotice;
}

export default function ChatRoomJoinApprovedCard({ data }: ChatRoomJoinApprovedCardProps) {
  return (
    <>
      <div className='flex cursor-pointer flex-col gap-10 p-20'>
        <Badge isBadge={!data.isRead} wrapperClassName={'flex-grow'}>
          <span className='flex min-h-[24px] w-full items-center text-Header14 text-black700'>
            {data.title}
          </span>
        </Badge>
        <span className={'break-all text-Body12 text-black500'}>{data.body}</span>
        <span className='flex justify-end text-Body10 text-black200'>
          {formatCardCreatedDate(data.createdAt)}
        </span>
      </div>
    </>
  );
}
