import { type ChangeEvent, useEffect, useState } from 'react';

const radioClasses =
  'z-[2px] h-10 w-10 appearance-none rounded-full bg-white600 ' +
  'checked:h-[14px] checked:w-[14px] checked:bg-[#007aff] checked:outline ' +
  'checked:outline-[#b0d6ff] checked:outline-[3px]';

const radioOptions = [
  { value: 0.2, position: 'justify-start', width: 'w-[30%]' },
  { value: 0.6, position: 'justify-center', width: 'w-[40%]' },
  { value: 1.0, position: 'justify-end', width: 'w-[30%]' },
];

interface AlarmVolumeRadioGroupProps {
  volume: number;
  onHandleChange: (volume: number) => void;
}

export default function AlarmVolumeRadioGroup({
  volume,
  onHandleChange,
}: AlarmVolumeRadioGroupProps) {
  const [selectedVolume, setSelectedVolume] = useState(0.6);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onHandleChange(Number.parseFloat(e.target.value));
    setSelectedVolume(Number.parseFloat(e.target.value));
  };

  useEffect(() => {
    setSelectedVolume(volume);
  }, [volume]);

  return (
    <div className='flex-center gap-10 py-10'>
      <span className='text-Body11 text-black200'>작게</span>
      <div className='soundbar relative flex h-16 flex-grow items-center justify-between'>
        {radioOptions.map((option) => (
          <label
            key={option.value}
            className={`z-[3px] flex ${option.width} cursor-pointer ${option.position}`}>
            <input
              type='radio'
              className={radioClasses}
              name='sound'
              checked={selectedVolume === option.value}
              value={option.value}
              onChange={handleChange}
            />
          </label>
        ))}
      </div>
      <span className='text-Body11 text-black200'>크게</span>
    </div>
  );
}
