import type { DataPushNotificationDataMap } from 'web/apis/push/data-push/data-push-notification';
import type { DataPushNotificationQuery } from 'web/apis/push/data-push/data-push-type';
import type { QueryKeyParamsMap } from 'web/apis/swaggers/query-key';

export const createDataPushNotificationQuery = <
  K extends keyof QueryKeyParamsMap,
  D extends keyof DataPushNotificationDataMap,
>(
  query: DataPushNotificationQuery<K, D>,
): DataPushNotificationQuery<K, D> => {
  return {
    ...query,
    getQueryKey: (data) => query.getQueryKey(data ?? ({} as DataPushNotificationDataMap[D])),
  };
};
