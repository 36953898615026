import {
  hospitalInfosAtom,
  selectedHospitalIDAtom,
  selectedNotificationAlarmAtom,
  tokenAtom,
  userIDAtom,
  userInfoAtom,
  userTypeAtom,
} from '@jotai/token';
import { SHARED_UTILS } from '@shared-utils/utils';
import { useMutation, useQuery } from '@tanstack/react-query';
import 'dayjs/locale/ko';
import { useAtom, useSetAtom } from 'jotai';
import 'jotai-devtools/styles.css';
import { useEffect, useMemo, useState } from 'react';
import 'react-day-picker/dist/style.css';
import 'react-image-gallery/styles/css/image-gallery.css';
import 'react-quill-new/dist/quill.snow.css';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import { usePushNotificationObserver } from 'web/hooks/use-push-notification-observer';
import ServiceManagerNavigationBar from 'web/shared/components/ServiceManagerNavigationBar/ServiceManagerNavigationBar';
import { DEV_HOSPITAL_ID, DEV_USER_ID as userIdFromDev } from 'web/shared/constants/temp-id';
import { isServiceManagerNavigatorVisibleState } from 'web/shared/states/is-service-manager-navigator-visible';
import '../../../packages/afterdoc-design-system/public/tailwind-inject.css';
import { apiClient } from './apis/instances/api-client';
import type {
  ApiElUpdateDeviceinfoHandlerBodyRequest,
  ApiHospitalsElFindParams,
  ApiUserElFindOneParams,
  ElectronNotificationSoundsHandlerParams,
  ElectronNotificationSoundsHandlerResponse,
  Hospital,
  User,
} from './apis/swaggers/swagger-docs';
import { DEV_USER_ID as userIdFromdev } from './shared/constants/temp-id';

interface ExtendedApiHospitalsElFindParams extends ApiHospitalsElFindParams {
  nurseID?: string;
  isEnabledSaaS?: boolean;
}

type UserInfo = User & {
  token: string;
  type: string;
  _id: string;
};

const fetchHospitalListsInfo = async (params: ExtendedApiHospitalsElFindParams) => {
  try {
    const response = await apiClient.v3.apiHospitalsElFind(params);
    const result = SHARED_UTILS.api.checkApiResponse<Hospital[]>(response.data);
    return result;
  } catch (error) {
    console.error('Error fetching hospital info', error);
    return undefined;
  }
};

const fetchHospitalInfo = async (params: ApiHospitalsElFindParams) => {
  try {
    const response = await apiClient.v3.apiHospitalsElFind(params);
    const result = SHARED_UTILS.api.checkApiResponse(response.data);
    return result[0];
  } catch (error) {
    console.error('Error fetching hospital info', error);
    return undefined;
  }
};

const fetchUserInfo = async (params: ApiUserElFindOneParams) => {
  const response = await apiClient.v2.apiUserElFindOne(params);
  return SHARED_UTILS.api.checkApiResponse(response.data) as User & {
    _id: string;
  };
};

const fetchNotificationSound = async (params: ElectronNotificationSoundsHandlerParams) => {
  const response = await apiClient.v3.electronNotificationSoundsHandler(params);
  return SHARED_UTILS.api.checkApiResponse<ElectronNotificationSoundsHandlerResponse['data']>(
    response.data,
  );
};

const updateDeviceInfo = async (params: ApiElUpdateDeviceinfoHandlerBodyRequest) => {
  const response = await apiClient.v3.apiElUpdateDeviceinfoHandler(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

const App = () => {
  const [isElectronEnabled, setIsElectronEnabled] = useState<boolean | null>(null);
  const [token, setToken] = useAtom(tokenAtom);

  const [isServiceManagerNavigatorVisible, setIsServiceManagerNavigatorVisible] = useAtom(
    isServiceManagerNavigatorVisibleState,
  );
  const setUserInfo = useSetAtom(userInfoAtom);
  const [hospitalInfos, setHospitalInfos] = useAtom(hospitalInfosAtom);
  const [selectedHospitalID, setSelectedHospitalID] = useAtom(selectedHospitalIDAtom);
  const [userID, setUserID] = useAtom(userIDAtom);
  const [userType, setUserType] = useAtom(userTypeAtom);

  const currentAlarmAtom = useMemo(() => selectedNotificationAlarmAtom(userID), [userID]);
  const [notificationAlarm, setNotificationAlarm] = useAtom(currentAlarmAtom);

  // 2000 ~ 2999: 병원 계정
  const isHospitalAccount = userType < 3000 && userType >= 2000;
  // 3000 ~ 3999: 서비스 계정
  const isServiceManagerAccount = userType >= 3000;

  const { data: userInfoFromServer, isFetched } = useQuery({
    queryKey: [
      QUERY_KEY.v2apiUserElFindOne,
      { userID: isElectronEnabled && userID.length > 0 ? userID : userIdFromdev },
    ] as const,
    enabled: (isElectronEnabled && userID.length > 0) || !isElectronEnabled,
    queryFn: ({ queryKey }) => fetchUserInfo(queryKey[1]),
  });

  const { data: hospitalListsInfo } = useQuery({
    queryKey: [
      QUERY_KEY.apiHospitalsElFind,
      {
        nurseID: userID,
        isEnabledSaaS: true,
      },
    ] as const,
    enabled: (isServiceManagerAccount && userID.length > 0) || !isElectronEnabled,
    queryFn: ({ queryKey }) => fetchHospitalListsInfo(queryKey[1]),
  });

  const { data: forDevHospitalListsInfo } = useQuery({
    queryKey: [
      `${QUERY_KEY.apiHospitalsElFind}-forDev`,
      {
        nurseID: userIdFromdev,
        isEnabledSaaS: true,
      },
    ] as const,
    enabled: isElectronEnabled !== null && isElectronEnabled === false,
    queryFn: ({ queryKey }) => fetchHospitalListsInfo(queryKey[1]),
  });

  const { data: hospitalInfo } = useQuery({
    queryKey: [
      QUERY_KEY.apiHospitalsElFind,
      {
        _id: selectedHospitalID,
      },
    ] as const,
    enabled: isHospitalAccount && !!selectedHospitalID,
    queryFn: ({ queryKey }) => fetchHospitalInfo(queryKey[1]),
  });

  const { data: soundList } = useQuery({
    queryKey: [
      QUERY_KEY.electronNotificationSoundsHandler,
      { hospitalID: selectedHospitalID },
    ] as const,
    enabled: !notificationAlarm.soundID && !!selectedHospitalID,
    queryFn: ({ queryKey }) => fetchNotificationSound(queryKey[1]),
  });

  const updateDeviceInfoMutation = useMutation({
    mutationFn: (params: ApiElUpdateDeviceinfoHandlerBodyRequest) => updateDeviceInfo(params),
  });

  useEffect(() => {
    if (window.electron) {
      setIsElectronEnabled(true);
    }
  }, []);

  useEffect(() => {
    if (userInfoFromServer && isFetched) {
      setUserInfo(userInfoFromServer);
    }
  }, [userInfoFromServer, isFetched]);

  useEffect(() => {
    if (hospitalListsInfo?.length && isElectronEnabled && isServiceManagerAccount) {
      setHospitalInfos(hospitalListsInfo);
      setIsServiceManagerNavigatorVisible(true);
      const hospitalIds = hospitalListsInfo.map((hospital) => hospital._id);
      window.electron?.ipcRenderer.send('Application.Set.v2HospitalIDs', {
        value: hospitalIds,
      });

      const hospitalID = hospitalListsInfo[0]._id as string;
      setSelectedHospitalID(hospitalID);
      window.electron?.ipcRenderer.send('Application.Set.hospitalID', hospitalID);
    }
  }, [hospitalListsInfo, isElectronEnabled]);

  useEffect(() => {
    if (hospitalInfo?._id && isElectronEnabled && isHospitalAccount) {
      setHospitalInfos([hospitalInfo]);
      setIsServiceManagerNavigatorVisible(false);
      window.electron?.ipcRenderer.send('Application.Set.hospitalID', hospitalInfo._id);
    }
  }, [hospitalInfo, isElectronEnabled]);

  useEffect(() => {
    if (!window?.electron) return;

    window.electron?.ipcRenderer.send('Application.Get.userInfo');

    return () => {
      window.electron?.ipcRenderer.removeAllListeners('Application.Get.userInfo');
    };
  }, []);

  useEffect(() => {
    if (!window?.electron) return;

    const loadUserInfoFromElectron = (_: Record<string, never>, payload: Payload<UserInfo>) => {
      const payloadUserInfo = payload.value;
      const token = payloadUserInfo.token;
      const userType = payloadUserInfo.type;

      const userID = payloadUserInfo._id;
      const hospitalID = payloadUserInfo.hospitalID?.[0];

      setToken(`Bearer ${token}`);
      setUserType(userType);
      setUserID(userID);
      setUserInfo(payloadUserInfo);
      setSelectedHospitalID(hospitalID);
      window.electron?.ipcRenderer.send('Application.Set.hospitalID', hospitalID);
    };

    window.electron?.ipcRenderer.on('Application.Get.userInfo', loadUserInfoFromElectron);

    return () => {
      window.electron?.ipcRenderer.removeAllListeners('Application.Get.userInfo');
    };
  }, []);

  useEffect(() => {
    if (!window?.electron) return;

    const handleDeviceInfoUpdate = (
      _: Record<string, never>,
      payload?: Payload<ApiElUpdateDeviceinfoHandlerBodyRequest>,
    ) => {
      if (payload && typeof payload === 'object' && payload.value) {
        console.info('payload: ', payload.value);
        updateDeviceInfoMutation.mutate(
          { ...payload.value },
          {
            onError: (error) => {
              console.error('Error occurred while updating device info:', error);
            },
          },
        );
      }
    };

    window.electron?.ipcRenderer.on('Application.Get.fcmToken', handleDeviceInfoUpdate);

    window.electron?.ipcRenderer.send('Application.Get.fcmToken');

    return () => {
      window.electron?.ipcRenderer.removeAllListeners('Application.Get.fcmToken');
    };
  }, []);

  useEffect(() => {
    if (window?.electron === undefined) {
      setIsElectronEnabled(false);
      setToken(`Bearer ${import.meta.env.VITE_DEV_TOKEN}`);

      const loadUserInfoFromDev = async () => {
        const response = await fetchUserInfo({ userID: userIdFromDev });
        const devUserInfo = response as User & {
          _id: string;
        };

        const userType = devUserInfo.type as number;
        const userID = devUserInfo._id;

        setUserType(userType);
        setUserID(userID);
        setUserInfo(devUserInfo);
        setSelectedHospitalID(DEV_HOSPITAL_ID);
      };

      loadUserInfoFromDev();

      if (forDevHospitalListsInfo) {
        setHospitalInfos(forDevHospitalListsInfo);
      }
    }
  }, [selectedHospitalID, forDevHospitalListsInfo]);

  useEffect(() => {
    if (soundList && notificationAlarm) {
      const defaultSound = soundList.find((item) => item.isDefault);
      if (defaultSound) {
        setNotificationAlarm({
          usingSound: true,
          soundID: defaultSound._id,
          path: defaultSound.path,
          volume: 0.6,
        });
      }
    }
  }, [soundList, setNotificationAlarm]);

  usePushNotificationObserver();

  if (!token?.length || !hospitalInfos?.length) {
    return null;
  }

  return <>{isServiceManagerNavigatorVisible && <ServiceManagerNavigationBar />}</>;
};

export default App;
