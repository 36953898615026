import { apiClient } from '@apis/instances/api-client';
import { useDataEventBusHandler } from '@apis/push/data-push/hooks/use-data-event-bus-handler';
import { QUERY_KEY } from '@apis/swaggers/query-key';
import type { ApiNoticeServicenoticeElCountParams } from '@apis/swaggers/swagger-docs';
import { useSelectedHospitalInfo } from '@shared/hooks/use-selected-hospital-info';
import { useUserInfo } from '@shared/hooks/use-user-info';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import type { ServiceNoticeTabs } from '@templates/ServiceNoticeDialog/types/service-notice-tabs';
import { useMemo } from 'react';
import { SHARED_UTILS } from 'utils/utils';

const fetchServiceNoticeUnreadCount = async (param: ApiNoticeServicenoticeElCountParams) => {
  const { data } = await apiClient.v3.apiNoticeServicenoticeElCount(param);
  return SHARED_UTILS.api.checkApiResponse(data);
};

export const useServiceNoticeUnreadCount = (activeTab: ServiceNoticeTabs['key']) => {
  const queryClient = useQueryClient();
  const { userId: userID } = useUserInfo();
  const { hospitalID } = useSelectedHospitalInfo();

  const { data: unreadCount } = useQuery({
    queryKey: [
      QUERY_KEY.apiNoticeServicenoticeElCount,
      { userID, hospitalID, isRead: false, category: activeTab === 'All' ? undefined : activeTab },
    ] as const,
    queryFn: ({ queryKey }) => fetchServiceNoticeUnreadCount(queryKey[1]),
  });

  const notificationConfigs = useMemo(
    () => [
      {
        codes: ['d_ServiceNotice_h'],
        handler: () => {
          queryClient.invalidateQueries({ queryKey: [QUERY_KEY.apiNoticeServicenoticeElCount] });
        },
      },
    ],
    [],
  );

  useDataEventBusHandler(notificationConfigs);

  return {
    unreadCount,
  };
};
