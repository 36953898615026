import type { ServiceNoticeTabs } from '@templates/ServiceNoticeDialog/types/service-notice-tabs';
import ButtonTab from 'afterdoc-design-system/components/Atoms/Tabs/components/ButtonTab';
import Tabs from 'afterdoc-design-system/components/Atoms/Tabs/components/Tabs';

interface ServiceNoticeDialogTabsProps {
  activeTab: ServiceNoticeTabs['key'];
  setActiveTab: (tab: ServiceNoticeTabs['key']) => void;
}

const tabItems: ServiceNoticeTabs[] = [
  { key: 'All', label: '전체' },
  { key: 'PatientManagement', label: '고객관리' },
  { key: 'ReservationManagement', label: '예약관리' },
];

export default function ServiceNoticeDialogTabs({
  activeTab,
  setActiveTab,
}: ServiceNoticeDialogTabsProps) {
  return (
    <>
      <Tabs value={activeTab} onChange={setActiveTab} className={'mt-16'}>
        {tabItems.map((item) => (
          <ButtonTab
            key={item.key}
            className='border-b border-b-white400'
            value={item.key}
            label={item.label}
          />
        ))}
      </Tabs>
    </>
  );
}
