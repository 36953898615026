import { ROUTES_PATH } from '@shared/components/NavigationBar/constants/menu-items';
import { useSelectedHospitalInfo } from '@shared/hooks/use-selected-hospital-info';
import { useUserInfo } from '@shared/hooks/use-user-info';
import { isServiceManagerNavigatorVisibleState } from '@shared/states/is-service-manager-navigator-visible';
import AlarmSoundSettingDialog from '@templates/AlarmSoundSettingDialog/AlarmSoundSettingDialog';
import { dialogService } from 'afterdoc-design-system/components/Molecules/Dialog/Dialog.service';
import Portal from 'afterdoc-design-system/shared/Portal/Portal';
import { useClickOutside } from 'hooks/use-click-outside';
import { useAtomValue } from 'jotai/index';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

export default function HospitalProfileMenu() {
  const navigate = useNavigate();
  const { representImage } = useSelectedHospitalInfo();
  const { realName } = useUserInfo();

  const isServiceManagerNavigatorVisible = useAtomValue(isServiceManagerNavigatorVisibleState);

  const divRef = useRef<HTMLDivElement>(null);

  const [isToggle, setIsToggle] = useState(false);

  const handlePageNavigation = (key: (typeof ROUTES_PATH)[keyof typeof ROUTES_PATH]) =>
    navigate(key);

  const handleLogout = () => {
    if (window?.electron === undefined) {
      alert('앱에서 실행해야 하는 기능입니다.');
    }
    window.electron?.ipcRenderer.send('Application.logout');
  };

  const showAlarmSoundSettingDialog = () => {
    dialogService.push(<AlarmSoundSettingDialog />, {
      id: 'alarm-sound-setting-dialog',
      titleProps: {
        title: '알림 소리 설정',
        subTitle: '채팅, 예약 등록 등 업무에 필요한 알림을 소리로 확인할 수 있습니다.',
      },
      isClosable: false,
      width: 380,
      wrapperClassName: 'h-fit mt-0 mb-0 overflow-visible',
    });
  };

  useClickOutside({
    id: uuidv4(),
    ref: divRef,
    contentRef: divRef,
    onClose: () => setIsToggle(false),
  });

  return (
    <>
      <div
        className='relative flex-center cursor-pointer flex-col gap-4 bg-white50 pt-8 pb-28'
        style={{
          backgroundImage: representImage?.paths?.[0],
        }}
        onClick={() => setIsToggle(!isToggle)}
        ref={divRef}>
        <div className='flex h-32 w-32 overflow-hidden rounded-r10 border border-white600'>
          <img
            className='h-full w-full object-cover'
            src={representImage?.paths?.[0]}
            alt='hospital_represent_image'
          />
        </div>
        <span className='line-clamp-2 text-center text-Body10 text-black500'>{realName || ''}</span>

        {isToggle && (
          <Portal
            style={{
              position: 'fixed',
              left: isServiceManagerNavigatorVisible ? '140px' : '70px',
              zIndex: 9999,
              bottom: '30px',
            }}>
            <div className='flex w-[115px] cursor-pointer flex-col rounded-r6 border border-white400 bg-white50 py-4 shadow-modal'>
              <span
                className='px-10 py-7 text-left text-Body12 text-black500 hover:bg-blueLight'
                onClick={() => handlePageNavigation(ROUTES_PATH.USER_INFO_SETTINGS)}>
                내 정보 설정
              </span>
              <span
                className='px-10 py-7 text-left text-Body12 text-black500 hover:bg-blueLight'
                onClick={() => showAlarmSoundSettingDialog()}>
                알림 소리 설정
              </span>
              <span
                className='px-10 py-7 text-left text-Header12 text-red500 hover:bg-blueLight'
                onClick={handleLogout}>
                로그아웃
              </span>
            </div>
          </Portal>
        )}
      </div>
    </>
  );
}
