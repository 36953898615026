import { apiClient } from '@apis/instances/api-client';
import { useDataEventBusHandler } from '@apis/push/data-push/hooks/use-data-event-bus-handler';
import { QUERY_KEY } from '@apis/swaggers/query-key';
import type { ApiChatroomElUnreadcountParams } from '@apis/swaggers/swagger-docs';
import type {
  MENU_ITEMS,
  ROUTES_PATH,
} from '@shared/components/NavigationBar/constants/menu-items';
import { useSelectedHospitalInfo } from '@shared/hooks/use-selected-hospital-info';
import { useUserInfo } from '@shared/hooks/use-user-info';
import type { DataNotificationPayload } from '@shared/utils/event-bus';
import { useQuery } from '@tanstack/react-query';
import Icon from 'afterdoc-design-system/components/Foundations/Icon/Icon';
import { debounce } from 'lodash-es';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { SHARED_UTILS } from 'utils/utils';

interface AdminChatMenuProps {
  item: (typeof MENU_ITEMS)[number];
  activatedKey: (typeof MENU_ITEMS)[number]['key'];
  onHandleClick: (key: (typeof ROUTES_PATH)[keyof typeof ROUTES_PATH]) => void;
}

const fetchUnreadCount = async (param: ApiChatroomElUnreadcountParams) => {
  const { data } = await apiClient.v2.apiChatroomElUnreadcount(param);
  return SHARED_UTILS.api.checkApiResponse(data);
};

export default function AdminChatMenu({ item, activatedKey, onHandleClick }: AdminChatMenuProps) {
  const { userId: userID } = useUserInfo();
  const { hospitalID } = useSelectedHospitalInfo();
  const [isBadge, setIsBadge] = useState(false);

  const { data, refetch } = useQuery({
    queryKey: [
      QUERY_KEY.v2apiChatroomElUnreadcount,
      { hospitalID, userID, isDoctor: true },
    ] as const,
    queryFn: ({ queryKey }) => fetchUnreadCount(queryKey[1]),
  });

  const handleNotificationRef = useRef<((payload: DataNotificationPayload) => void) | null>(null);

  const stableRefetch = useCallback(() => refetch(), [refetch]);

  useEffect(() => {
    const ipcRenderer = window.electron?.ipcRenderer;
    if (!ipcRenderer) return;

    const debouncedRefetch = debounce(() => {
      stableRefetch();
    }, 1000);

    const eventHandler = () => {
      debouncedRefetch();
    };

    ipcRenderer.on('Application.adminUnreadRefresh', eventHandler);

    return () => {
      ipcRenderer.removeListener('Application.adminUnreadRefresh', eventHandler);
      debouncedRefetch.cancel();
    };
  }, [stableRefetch]);

  useEffect(() => {
    if (data && data.length > 0) {
      const index = data.findIndex((item) => item._id === userID);
      if (index >= 0) {
        setIsBadge(data[index].total > 0);
      }
    }
  }, [data]);

  const notificationConfigs = useMemo(
    () => [
      {
        codes: ['d_ChatroomUpdateDoctor_el'],
        handler: (payload: DataNotificationPayload) => handleNotificationRef.current?.(payload),
      },
    ],
    [],
  );

  useDataEventBusHandler(notificationConfigs);

  useEffect(() => {
    handleNotificationRef.current = (payload: DataNotificationPayload) => {
      const senderID = payload.data?.senderID;
      if (senderID !== userID) {
        refetch();
      }
    };
  }, [userID]);

  return (
    <>
      <div
        onClick={() => onHandleClick(item.key)}
        className='relative flex-center cursor-pointer flex-col gap-2 bg-white50 pt-10 pb-18'>
        <Icon
          name={activatedKey === item.key ? item.activeIcon : item.nonActiveIcon}
          color={activatedKey === item.key ? 'black700' : 'black100'}
          size={24}
        />
        <div className={activatedKey === item.key ? 'text-Body10Bold' : 'text-Body10'}>
          {item.title}
        </div>
        {isBadge && (
          <span className='absolute top-8 right-18 h-8 w-8 rounded-full border border-white50 bg-red500' />
        )}
      </div>
      <div className='mx-10 border-b-2 border-b-white400' />
    </>
  );
}
