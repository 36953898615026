import { Suspense, lazy } from 'react';
import { Navigate, createBrowserRouter } from 'react-router-dom';
import AppContainer from 'web/App.container';
import { ROUTES_PATH } from 'web/shared/components/NavigationBar/constants/menu-items';
import FullLoading from '../components/FullLoading/FullLoading';

// Error Page
const ErrorPage = lazy(() =>
  import('../../pages/ErrorPage').then((module) => ({ default: module.default })),
);

/** Main Routes Pages */
// 고객상담
const CustomerChatPage = lazy(() =>
  import('../../pages/CustomerChatPage').then((module) => ({ default: module.default })),
);
// 고객관리
const CustomerManagementPage = lazy(() =>
  import('../../pages/CustomerManagementPage').then((module) => ({ default: module.default })),
);
// 자동화
const AutomationPage = lazy(() =>
  import('../../pages/AutomationPage').then((module) => ({ default: module.default })),
);
// 콘텐츠
const ContentPage = lazy(() =>
  import('../../pages/ContentPage').then((module) => ({ default: module.default })),
);
// 원내매뉴얼
const HospitalManualPage = lazy(() =>
  import('../../pages/HospitalManualPage').then((module) => ({ default: module.default })),
);
// 병원설정
const HospitalSettingPage = lazy(() =>
  import('../../pages/HospitalSettingPage').then((module) => ({ default: module.default })),
);
// 내정보설정
const UserInfoSettingPage = lazy(() =>
  import('../../pages/UserInfoSettingPage').then((module) => ({ default: module.default })),
);

const routes = [
  {
    path: ROUTES_PATH.HOME,
    element: <AppContainer />,
    children: [
      {
        index: true,
        element: <Navigate to={ROUTES_PATH.CUSTOMER_CHAT} replace />,
      },
      {
        path: ROUTES_PATH.RESERVATION_MANAGEMENT,
      },
      {
        path: ROUTES_PATH.ADMIN_CHAT,
      },
      {
        path: ROUTES_PATH.CUSTOMER_CHAT,
        element: (
          <Suspense fallback={<FullLoading />}>
            <CustomerChatPage />
          </Suspense>
        ),
      },
      {
        path: ROUTES_PATH.CUSTOMER_MANAGEMENT,
        element: (
          <Suspense fallback={<FullLoading />}>
            <CustomerManagementPage />
          </Suspense>
        ),
      },
      {
        path: ROUTES_PATH.AUTOMATION,
        element: (
          <Suspense fallback={<FullLoading />}>
            <AutomationPage />
          </Suspense>
        ),
      },
      {
        path: ROUTES_PATH.CONTENT,
        element: (
          <Suspense fallback={<FullLoading />}>
            <ContentPage />
          </Suspense>
        ),
      },
      {
        path: ROUTES_PATH.HOSPITAL_MANUAL,
        element: (
          <Suspense fallback={<FullLoading />}>
            <HospitalManualPage />
          </Suspense>
        ),
      },
      {
        path: ROUTES_PATH.HOSPITAL_SETTING,
        element: (
          <Suspense fallback={<FullLoading />}>
            <HospitalSettingPage />
          </Suspense>
        ),
      },
      {
        path: ROUTES_PATH.USER_INFO_SETTINGS,
        element: (
          <Suspense fallback={<FullLoading />}>
            <UserInfoSettingPage />
          </Suspense>
        ),
      },
      {
        path: ROUTES_PATH.ERROR,
        element: (
          <Suspense fallback={<FullLoading />}>
            <ErrorPage />
          </Suspense>
        ),
      },
    ],
  },
];

const AppRoutes: ReturnType<typeof createBrowserRouter> = createBrowserRouter(routes);

export default AppRoutes;
