import { isServiceManagerNavigatorVisibleState } from '@shared/states/is-service-manager-navigator-visible';
import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import ServiceNoticeDialog from '@templates/ServiceNoticeDialog/ServiceNoticeDialog';
import { useServiceNoticeUnreadCount } from '@templates/ServiceNoticeDialog/hooks/use-service-notice-unread-count';
import Icon from 'afterdoc-design-system/components/Foundations/Icon/Icon';
import Portal from 'afterdoc-design-system/shared/Portal/Portal';
import { useAtomValue } from 'jotai/index';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function ServiceNoticeMenu() {
  const navigate = useNavigate();

  const { unreadCount } = useServiceNoticeUnreadCount('All');

  const isServiceManagerNavigatorVisible = useAtomValue(isServiceManagerNavigatorVisibleState);

  const [isActive, setActive] = useState(false);
  const [isBadge, setIsBadge] = useState(false);

  const handleClose = useCallback(() => setActive(false), []);

  const handleNavigate = useCallback((href: string) => {
    setActive(false);
    const [basePath, queryString] = href.split('?');
    const searchParams = new URLSearchParams(queryString);
    const newHref = `${basePath}?${searchParams.toString()}`;
    navigate(newHref);
  }, []);

  useEffect(() => {
    if (unreadCount && unreadCount > 0) {
      setIsBadge(true);
    } else {
      setIsBadge(false);
    }
  }, [unreadCount]);

  return (
    <div className='bg-white50 pt-10 pb-18'>
      <div
        className='relative flex flex-col-center flex-shrink-0 cursor-pointer gap-2'
        onClick={() => setActive(!isActive)}>
        <Icon
          name={isActive ? 'bell' : 'bell-outline'}
          color={isActive ? 'black700' : 'black100'}
          size={24}
        />
        <div className={customTwMerge(isActive ? 'text-Body10Bold' : 'text-Body10')}>알림</div>
        {isBadge && (
          <span className='absolute top-[-2px] right-18 h-8 w-8 rounded-full border border-white50 bg-red500' />
        )}
      </div>
      {isActive && (
        <Portal
          style={{
            position: 'absolute',
            left: isServiceManagerNavigatorVisible ? '150px' : '80px',
            visibility: isActive ? 'visible' : 'hidden',
            zIndex: 9999,
            top: '10px',
            bottom: 0,
          }}>
          <ServiceNoticeDialog onClose={handleClose} handleNavigate={handleNavigate} />
        </Portal>
      )}
    </div>
  );
}
