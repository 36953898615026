import { atomWithReset } from 'jotai/utils';
import { ROUTES_PATH } from 'web/shared/components/NavigationBar/constants/menu-items';

export const showModalBeforeRouteState = atomWithReset(
  Object.keys(ROUTES_PATH).reduce(
    (acc, key) => ({
      // biome-ignore lint/performance/noAccumulatingSpread: <explanation>
      ...acc,
      [key as keyof typeof ROUTES_PATH]: {
        showModal: false,
        nextPath: '',
      },
    }),
    {} as { [key in keyof typeof ROUTES_PATH]: { showModal: boolean; nextPath: string } },
  ),
);
