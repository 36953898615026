import type { ServiceNotice } from '@apis/swaggers/swagger-docs';
import { formatCardCreatedDate } from '@templates/ServiceNoticeDialog/functions/format-card-created-date';
import Badge from 'afterdoc-design-system/components/Atoms/Badge/Badge';
import Divider from 'afterdoc-design-system/components/Atoms/Divider/Divider';
import { Fragment } from 'react';

interface ReservationUpdatedCardProps {
  data: ServiceNotice;
}

const processContent = (content: string | undefined, keyword: string): string[] => {
  if (!content) return [];
  const lines = content.split('\n');
  if (lines[0].replace(/\s+/g, '') === keyword.replace(/\s+/g, '')) {
    lines.shift();
  }
  while (lines[0]?.trim() === '') {
    lines.shift();
  }

  return lines;
};

export default function ReservationUpdatedCard({ data }: ReservationUpdatedCardProps) {
  return (
    <>
      <div className='flex cursor-pointer flex-col gap-10 p-20'>
        <Badge isBadge={!data.isRead} wrapperClassName={'flex-grow'}>
          <span className='flex min-h-[24px] w-full items-center text-Header14 text-black700'>
            {data.title}
          </span>
        </Badge>
        <span className={'break-all text-Body12 text-black500'}>{data.body}</span>
        <div className='flex flex-col rounded-r10 border border-white400 bg-white100 px-16 py-12 text-Body12 text-black500'>
          <ContentSection
            title='변경 전'
            content={data.beforeContent}
            colorClassName='bg-white400 text-black800'
          />
          <Divider type='line' className='my-10' />
          <ContentSection
            title='변경 후'
            content={data.afterContent}
            colorClassName='bg-blue50 text-blue500'
          />
        </div>
        <span className='flex justify-end text-Body10 text-black200'>
          {formatCardCreatedDate(data.createdAt)}
        </span>
      </div>
    </>
  );
}

interface ContentSectionProps {
  title: string;
  content?: string;
  colorClassName: string;
}

const ContentSection = ({ title, content, colorClassName }: ContentSectionProps) => (
  <div className='flex w-full gap-10'>
    <span
      className={`h-[20px] w-43 flex-center flex-shrink-0 rounded-r10 text-Body10 text-align ${colorClassName}`}>
      {title}
    </span>
    <div className='flex-grow break-all'>
      {processContent(content, title)
        .filter((line) => line.trim() !== '')
        .map((line, index) => (
          <Fragment key={index}>
            {line}
            <br />
          </Fragment>
        ))}
    </div>
  </div>
);
